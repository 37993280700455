import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
import { Client, Account } from 'appwrite';
import { v4 as uuidv4 } from 'uuid';
import { initCheckoutModal } from '../shared/modal';
export default class RegistrationController extends Controller {

  connect() {
    this.email_valid = false
    this.full_name_valid = false
    this.password_valid = false
    this.password_confirm_valid = false
    this.password = ""
    this.password_confirm = ""
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
    console.log(this.account);
  }

  submit(e) {
    e.preventDefault()
    if(this.email_valid && this.full_name_valid && this.password_valid && this.password_confirm_valid) {
      let form = $('#registration_user');
      this.register()
    }
    setTimeout(() => {
      $('#registration_user .submit_button').prop('disabled', false)
    }, 100)

  }

  register() {
    this.account.create(
      uuidv4(),
      this.email,
      this.password,
      this.full_name
    ).then(async (response) => {
      $('#modal_menu').modal('hide')
      $('.loading_app_container').removeClass('d-none')
      await this.createSession()
      await this.signIn()
      await this.verifyEmail()
    }, function (error) {
      if(error.code == 409) {
        $('.user_email input').addClass('is-invalid')
        $('.user_email small').text("Un compte avec cette adresse email existe déjà.")
      }
      console.log(error); // Failure
      console.log(error.code);
    })
  }

  createSession() {
    return this.account.createEmailSession(this.email, this.password).catch((error) => { console.log(error) });
  }

  signIn() {
    return this.account.createJWT().then((response) => {
      localStorage.setItem('jwt', response.jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: response.jwt, init_user: true}
      }).done((data) => {
        $('.loading_app_container').addClass('d-none')
        $('#modal_menu').modal('hide')
        flashMessage('', 'Vous allez recevoir un lien de vérifications dans quelques instants par mail. Merci de cliquer sur le lien pour vérifier votre compte.', 'success')
        $.get('/reload_modal')
      })
    }, function (error) {
      console.log(error); // Failure
    });
  }

  verifyEmail() {
    return this.account.createVerification(`${process.env.callbackEndPoint}/verifyaccount/`).catch((error) => {
      console.log(error);
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  check_email(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.email_valid = false
      input.next().text("Veuillez renseigner une adresse email")
      return false;
    }

    if(!this.validateEmail(input.val())) {
      input.addClass('is-invalid')
      this.email_valid = false
      input.next().text('Veuillez renseigner une adresse email valide')
      return false;
    }
    this.email_valid = true
    this.email = input.val()
  }

  email_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_email(input)
  }

  check_full_name(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.full_name_valid = false
      input.next().text("Veuillez renseigner votre prénom et votre nom")
      return false;
    }
    input.addClass('is-valid')
    this.full_name_valid = true
    this.full_name = input.val()
  }

  full_name_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_full_name(input)
  }

  check_password(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Veuillez renseigner un mot de passe")
      return false;
    }
    if(input.val().length < 8) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Votre mot de passe n'est pas assez long (minimum 8 charactères)")
      return false;
    }
    input.addClass('is-valid')
    this.password_valid = true
  }

  password_change(e) {
    let input = $(e.currentTarget)
    this.password = $(e.currentTarget).val()
    input.removeClass('is-valid is-invalid')
    this.check_password(input)
  }

  check_password_confirm(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Veuillez confirmer votre mot de passe")
      return false;
    }
    if(input.val() != this.password) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Les deux mots de passe sont différents")
      return false;
    }
    input.addClass('is-valid')
    this.password_confirm_valid = true
  }

  password_confirm_change(e) {
    let input = $(e.currentTarget)
    this.password_confirm = $(e.currentTarget).val()
    input.removeClass('is-valid is-invalid')
    this.check_password_confirm(input)
  }
}
