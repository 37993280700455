import { Controller } from 'stimulus';
export default class SupplementselectionController extends Controller {
  static targets = ['input']

  connect() {
    this.set_total()
  }

  set_total() {
    var total = 0.0
    $.each(this.inputTargets, (index, input) => {
      total += this.input_price(input)
    })
    $(`#${this.element.id} .total_supplement`).html(total)
  }

  toggle(e) {
    e.preventDefault()
    if($(e.currentTarget).siblings().prop('checked')) {
      $(e.currentTarget).siblings().prop('checked', false)
      $(e.currentTarget).parents('.basket_product_mix_quantity').removeClass('active')
      $(e.currentTarget).parents('.basket_product_mix_quantity').find('input').val(0)
    } else if ((this.max && this.amount < this.max) || !this.max) {
      $(e.currentTarget).siblings().prop('checked', true)
      $(e.currentTarget).parents('.basket_product_mix_quantity').addClass('active')
      $(e.currentTarget).parents('.basket_product_mix_quantity').find('input').val(1)
    }
    this.set_total()
  }

  input_price(input) {
    return (parseFloat($(input).val()) * parseFloat($(input).data().price))
  }

  summary() {
    var supplements = []

    $.each(this.inputTargets, (index, input) => {
      if($(input).val() > 0) {
        supplements.push(`${$(input).data().name} (${this.input_price(input)}€)`)
      } else if ($(input).val() > 0) {
        supplements.push(`${$(input).val()} ${$(input).data().name} (${this.input_price(input)}€)`)
      }
    })
    if(supplements.length) {
      return ` - Supplément(s) : ${supplements.join(' - ')}`
    } else {
      return ''
    }
  }

  increase(e) {
    $(e.currentTarget).parent().find('input').val(parseFloat($(e.currentTarget).parent().find('input').val()) + 1)
    this.set_total()
  }

  decrease(e) {
    let new_value = parseFloat($(e.currentTarget).parent().find('input').val()) - 1
    $(e.currentTarget).parent().find('input').val(new_value)
    this.set_total()
    if(new_value == 0) {
      $(e.currentTarget).parents('.basket_product_mix_quantity').find('.custom-checkbox input').prop('checked', false)
      $(e.currentTarget).parents('.basket_product_mix_quantity').removeClass('active')
    }
  }

  change(e) {
    this.set_total()
  }
}
