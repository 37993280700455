import { Controller } from 'stimulus';
export default class PasswordbalancingController extends Controller {

  connect() {
    var hostToCall = window.location.origin;
    if (hostToCall.endsWith('/')) hostToCall = hostToCall.slice(0, -1);
    var queryString = window.location.search;
    //var appstoreFail = hostToCall + "/verifyEmail" + queryString;
    var appUrlScheme = "smartcollect://passwordRecovery" + queryString;
    setTimeout(() => {
      //window.location = appstoreFail;
      if($(window).width() < 900) {
        window.location = `${process.env.mobileUrl}/password/recovery${queryString}`
      } else {
        Turbolinks.visit(`/password/recovery${queryString}`)
      }
    }, 2500);

    window.open(appUrlScheme, "_self");
  }
}
