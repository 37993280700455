import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
export default class AssociationcodeController extends Controller {

  connect() {
    this.input = $(this.element).find('#association_code_input')
  }

  previous() {
    $('#modal_menu').removeClass('show-custom-content') 
  }

  keyUp() {
    this.input.removeClass('is-invalid')
  }

  submit() {
    $('.loading_app_container').removeClass('d-none')
    if(this.input.val().length == 0) {
      this.addError('Veuillez renseigner un code de connexion')
      return
    }
    if(this.input.val().length != 6) {
      this.addError('Le code doit contenir exactement 6 chiffres')
      return
    }

    $.ajax({
      method: 'POST',
      url: '/association_code/send_code',
      data: {code: this.input.val()}
    }).done(data => {
      if(data == 200) {
        $('.loading_app_container').addClass('d-none')
        $('#modal_menu').modal('hide')
        flashMessage('', 'La boutique a bien été associée.', 'success')
        $.get('/reload_modal')
      } else {
        this.addError("Désolé, le code utilisé n'a pas pu être vérifié")
      }
    })
  }

  sendNewInvitation() {
    $.ajax({
      method: 'GET',
      url: '/association_code/new_invitation',
    }).done(() => {
      flashMessage('', 'Une nouvelle invitation vous a été envoyée par email.', 'success')
    })

  }

  addError(text) {
    $('.loading_app_container').addClass('d-none')
    this.input.addClass('is-invalid')
    this.input.next().text(text)
  }
}
