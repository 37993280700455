require('bootstrap/js/dist/modal');

export function deliveryModal(e) {
  $('#modal_form .modal-content').html(`
    <div class='delivery-modal' data-controller='deliverymodal' data-delivery="${$(e.currentTarget).data().delivery}" data-id="${$(e.currentTarget).get(0).id}">
      <p class="delivery-modal__title">
        <span class='font-bold text-42'>Date de retrait</span>
      </p>
      <div class='position-relative mb-2'>
        <select class='delivery_date_select' data-action="deliverymodal#dateChanged"></select>
        <div class='select_arrow position-absolute'>
          <svg width="24px" height="24px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path d="M17 11.7494V14.916L12 11.0827L7 14.916V11.7494L12 7.91602L17 11.7494Z" fill="#000000" transform="rotate(180, 12, 12)"></path></svg>
        </div>
      </div>
      <div class='position-relative'>
        <select class='delivery_minute_select'></select>
        <div class='select_arrow position-absolute'>
          <svg width="24px" height="24px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path d="M17 11.7494V14.916L12 11.0827L7 14.916V11.7494L12 7.91602L17 11.7494Z" fill="#000000" transform="rotate(180, 12, 12)"></path></svg>
        </div>
      </div>
      <div class='d-flex justify-content-end align-items-center mt-4'>
        <p class='mb-0 ml-4 btn btn-outline-primary' data-action="click->deliverymodal#validDate">Valider</p>
      </div>
      <div class='position-absolute close_delivery_modal' data-action="click->deliverymodal#closeModal">
        <svg style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve">
          <style type="text/css">
          .st0{fill:#231F20;}
          </style>
          <path class="st0" d="M284.3,245.1l110.9-110.9c7.8-7.8,7.8-20.5,0-28.3s-20.5-7.8-28.3,0L256,216.8L145.1,105.9  c-7.8-7.8-20.5-7.8-28.3,0s-7.8,20.5,0,28.3l110.9,110.9L116.9,355.9c-7.8,7.8-7.8,20.5,0,28.3c3.9,3.9,9,5.9,14.1,5.9  c5.1,0,10.2-2,14.1-5.9L256,273.3l110.9,110.9c3.9,3.9,9,5.9,14.1,5.9s10.2-2,14.1-5.9c7.8-7.8,7.8-20.5,0-28.3L284.3,245.1z"/>
        </svg>
      </div>
    </div>

  `)
  $('#modal_form').addClass('modal_delivery_date')
  $('#modal_form').modal('show')
  $('.modal-backdrop').eq(-1).removeClass("modal-backdrop");
}
