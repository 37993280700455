import { MainController } from './main_controller';

import { initCheckoutModal } from '../shared/modal'
import { UpdatingLimitedProductQuantity } from '../shared/updating_limited_product_quantity';
import { numberToEuro } from '../shared/number_to_euro';
import { flashMessage } from '../shared/notify';

import { ProductVariationHandler } from '../classes/product_variation_handler';
import { MixAttributeHandler } from '../classes/mix_attribute_handler';
import { SupplementSelectionHandler } from '../classes/supplement_selection_handler';
import { SupplementSelectionBuilder } from '../classes/supplement_selection_builder';

export default class BasketproductController extends MainController {
  static targets = ['slide']

  connect() {
    this.element['controller'] = this
    this.basketProduct = $(this.element).data().basketProduct

    $('#basket_product_limited_smart_collect_product_id').removeClass('is-valid')
    this.shop_id = $(this.element).data().shopId
    this.productVariationHandler = new ProductVariationHandler($('#basket_product_productVariationId'))
    this.groupSupplements = $(this.element).data().groupSupplement.sort((a, b) => {
      let a_order = $(this.element).data().localGroupSupplements[a.id] ? parseInt($(this.element).data().localGroupSupplements[a.id].order) : 1000
      let b_order = $(this.element).data().localGroupSupplements[b.id] ? parseInt($(this.element).data().localGroupSupplements[b.id].order) : 1000

      return a_order - b_order
    })    
    this.values = {basket_product_quantity: "1"}
    this.valid = true
    if($(this.element).data().maximum) {
      this.maximum = parseInt($(this.element).data().maximum  )
    }
    this.mixAttributeHandler = new MixAttributeHandler      
    this.supplementSelectionHandler = new SupplementSelectionHandler
    if($('#basket_product_productPriceUnitId :checked').length == 0) {
      $('#basket_product_productPriceUnitId .custom-control-input').eq(0).prop('checked', true) 
    }
    setTimeout(() => {
      this.productPriceUnitChange()
    }, 200)
    
    $('.basket_product_form_description_container').prependTo($('.form_slide.active'))
    $('#basket-form-footer').get(0).controller.initForm()
    $('.basket_item_modal').get(0).controller.initForm()
  }

  productPriceUnitChange() {
    let unitId = $('#basket_product_productPriceUnitId :checked').data().unitId
    $('#basket_product_unitId').val(unitId)
    $('.basket-form-quantity-container').show()
    $('.basket-form-quantity-container__symbol').text($('#basket_product_productPriceUnitId :checked').data().unit)
    if(['Kilogramme(s)', 'Gramme(s)', 'Litre(s)'].includes($('#basket_product_productPriceUnitId :checked').data().unit)) {
      $('#basket-form-footer .basket-form-quantity-container').addClass('basket-form-quantity-container--custom')
      $('#basket_product_footer_quantity').prop('disabled', false)
    } else {
      let value = parseInt($('#basket_product_footer_quantity').val())
      $('#basket_product_quantity').val(value)
      $('#basket_product_footer_quantity').val(value)
      $('#basket-form-footer .basket-form-quantity-container').removeClass('basket-form-quantity-container--custom')
      $('#basket_product_footer_quantity').prop('disabled', true)
    }
    this.productVariationHandler.populate(unitId, $(this.element), $('.product_variations_container'), $(this.element).data().basketProduct) 
    this.handleMixables()
    this.supplementSelectionHandler.checkQuantities()    
    setTimeout(() => {
      this.checkValid(false)
    }, 300)
  }

  getMixableAttribute(uuid) {
    return this.mixAttributeHandler.getMixableAttribute(uuid)
  }  

  handleMixables() {
    $('.mix_attributes_container').html('')
    this.addedGroupSupplements = []
    let result = this.mixAttributeHandler.populate(
      $(this.element).data().mixAttributes[$('#basket_product_productPriceUnitId :checked').data().unitId],
      $('#basket_product_productPriceUnitId :checked').data().unit.toLowerCase().replace('(s)', ''),
      $(this.element),
      $('.mix_attributes_container'),
      this.groupSupplements,
      undefined,
      'basket_product'
    )
    this.mixableMode = result.mode
    $('.basket-form-quantity-container').toggle(!$(this.element).data().mixAttributes[$('#basket_product_productPriceUnitId :checked').data().unitId])
    this.addedGroupSupplements = result.groupSupplementIds
    $('#basket-form-slide--supplements').html('')
    $('#basket-form-slide--supplements').removeClass('basket-form-slide')
    let groupSupplements = this.groupSupplements.filter((group) => {return !this.addedGroupSupplements.includes(group.id)})
    if(groupSupplements.length) {
      $.each(groupSupplements, (i, groupSupplement) => {
        $('#basket-form-slide--supplements').append(
          new SupplementSelectionBuilder(groupSupplement, $(this.element).data(), false, 'basket_product').build()
        )
      })
      $('#basket-form-slide--supplements').addClass('basket-form-slide')
    }
  }  

  reloadMixables() {
    let attrs = []
    $.each($('.mixable_input'), (index, input) => {
      for (let i = 0; i < parseInt($(input).val()); i++) {
        attrs.push($(input).data().mixable)
      }
    })

    $('#basket_product_mixableAttributes').val(attrs.join('_'))
    if(this.mixableMode == 'quantity') {
      $('#basket_product_quantity').val(attrs.length)
    } else {
      $('#basket_product_quantity').val(1)

    } 
  }

  basePrice() {
    let productVariationPrice = this.productVariationHandler.selectVariation()
    if(productVariationPrice) {
      return productVariationPrice
    } else {
      return parseFloat($('#basket_product_productPriceUnitId :checked').data().price)
    }
  }

  change_limited_id() {
    this.maximum = limited[$('#basket_product_limited_smart_collect_product_id').val()]
    if(parseInt($('#basket_product_quantity').val()) > this.maximum) {
      $('#basket_product_quantity').val(this.maximum)
    }
  }

  supplementChanged(e) {
    this.supplementSelectionHandler.quantityChanged($(e.currentTarget), $(e.currentTarget).data().factor == 'minus' ? -1 : 1)
    this.checkValid(false)
  }

  attributeSelected(e) {
    this.productVariationHandler.attributeSelected($(e.currentTarget))
    this.reloadMixables()
    this.checkValid(false)
  }

  increase(e) {
    if(this.maximum && this.maximum == parseInt($('#basket_product_quantity').val())) {
      return
    }
    $(e.currentTarget).parent().find('input').val(parseFloat($(e.currentTarget).parent().find('input').val()) + 1)
    this.changed($(e.currentTarget).parent().find('input'))
  }

  decrease(e) {
    let new_value = parseFloat($(e.currentTarget).parent().find('input').val()) - 1
    if(new_value >= 1) {
      $(e.currentTarget).parent().find('input').val(new_value)
      this.changed($(e.currentTarget).parent().find('input'))
    }
  }

  checkValid(displayErrors) {
    this.valid = true
    $.each($('.basket_product_attribute'), (i, e) => {
      if($(e).find(':checked').length == 0) {
        this.valid = false
      }
    })
    if(parseFloat($('#basket_product_quantity').val()) == 0) {
      this.valid = false
    }
    $.each($('.mixable_slide'), (i, mixable_slide) => {
      if(!mixable_slide.controller.getValid()) {
        this.valid = false
      }
    })

    if(this.valid) {
      $('.basket-form-total-price').show()
      $('.product_next_button').addClass('active')
      this.calcTotal()
    } else {
      $('.basket-form-total-price').hide()
      $('.product_next_button').removeClass('active')
    }
  }

  calcTotal() {
    let total = this.basePrice()
    if($('.mixable_slide').length) {
      $.each($('.mixable_slide'), (i, mixable_slide) => {
        total += mixable_slide.controller.getTotal()
      })
    } else {
      total = total * parseFloat($('#basket_product_quantity').val())
    }    
    $.each($('.basket-form-supplement-selections .basket-form-quantity-buttons input'), (i, input) => {
      total += parseInt($(input).val()) * parseFloat($(input).data().price)
    })

    $.each($('.seasonings_checkboxes :checked'), (i, input) => {
      total += parseFloat($(input).data().price)
    })



    $('.basket-form-total-price').text(numberToEuro(total))
  }

  submit() {
    this.checkValid(true)
    if(!this.valid) {
      return 
    }
    let type = $('#form_slider').hasClass('new_basket_product') ? 'POST' : 'PUT'
    $('.basket_product_mix_quantity input').prop('disabled', false)
    $('.loading_app_container').removeClass('d-none')
    $.ajax({
      type: type,
      url: $('#form_slider').attr('action'),
      data: $('#form_slider').serialize(),
      success: (data) => {
        $('.loading_app_container').addClass('d-none')
        if(data.code != 200) {
          $('.modal').modal('hide')
          flashMessage('', "Votre produit a été supprimé du panier", 'warning')
          this.getControllerByIdentifier('cart').refresh()
          return
        }
        if(data.out_of_stock) {
          $('.form-slider').html(`
            <div class='p-4'>
              <p>${data.out_of_stock}</p>
              <div class="d-flex justify-content-end">
                <p class="mb-0 btn btn-primary" data-dismiss="modal">
                  Continuer mes achats
                </p>
              </div>
            </div>
          `)
        } else {
          $('.modal').modal('hide')
          $('.shop_warning_other_shop').hide()
          this.getControllerByIdentifier('cart').refresh()
          $.each(data.restricted, (index, number) => {
            $(`.shop_product_${number} .restricted_overlay`).hide()
            $(`.shop_product_${number}`).data('itemId', number)
          })
          UpdatingLimitedProductQuantity(data.updating_limited)
          if(data.limited) {
            $.get('/check_current_user', function(data) {
              if(data.current_user) {
                initCheckoutModal()
                $.ajax({url: data.url, dataType: 'script'});
              } else {
                $.get('/users/sign_in?before_checkout=true&limited=true')
              }
            })
          }
        }
      }
    })
  }

  checkPromotions() {
    if(!(window.promotions_items && window.promotions_items[this.values.product_price_unit_ppu])) { return false }
    let promotion = window.promotions_index[window.promotions_items[this.values.product_price_unit_ppu]]
    let amount = promotion.resultType == 0 ? promotion.promotionItem[0].factor : (promotion.promotionItem[0].factor + promotion.resultValue)
    var html = `Ce produit fait partie de la promotion "${promotion.name}". Si vous prenez ${amount} ${this.values.product_price_unit}, vous pourrez bénéficier `
    html += promotion.resultType == 0 ? `d'une formule à ${promotion.resultValue} €.` : `de ${promotion.resultValue} ${this.values.product_price_unit} offert(s).`
    $('.basket_product_promotion_display').html(html)
  }
}
