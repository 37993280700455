export class SupplementSelectionHandler {
  checkQuantities() {
    $.each($('.basket-form-supplement-selections .basket-form-quantity-buttons'), (i, e) => {
      this.checkQuantity($(e))
    })
  }
  quantityChanged(button, quantity) {
    if(button.hasClass('hidden')) { return }
    let input = button.parent().find('input')
    input.val(parseInt(input.val()) + quantity)
    this.checkQuantity(button.parent())
  }

  checkQuantity(element) {
    element.toggleClass('has-quantity', parseInt(element.find('input').val()) > 0)
    element.find('div').eq(0).toggleClass('hidden', parseInt(element.find('input').val()) == 0)    
    let parent_section = element.parents('.basket-form-section').eq(0)
    if(parent_section.data().maxQuantity && parseInt(parent_section.data().maxQuantity) > 0) {
      let total = 0
      $.each(parent_section.find('.basket-form-quantity-buttons input'), (i, input) => {
        total += parseInt($(input).val())
      })
      parent_section.find('.basket-form-supplement-selections__plus-button').toggleClass('hidden',
        total == parseInt(parent_section.data().maxQuantity)
      )
    }
  }
}