import { MainController } from './main_controller';

import { MixAttributeHandler } from '../classes/mix_attribute_handler';
import { SupplementSelectionBuilder } from '../classes/supplement_selection_builder';
import { DishSetSelectionVariationHandler } from '../classes/dish_set_selection_variation_handler';

import { numberToEuro } from '../shared/number_to_euro';
import { classAgregator } from '../shared/class_agregator';

export default class DishsetselectionController extends classAgregator(MainController, DishSetSelectionVariationHandler) {
  connect() {
    this.element['controller'] = this
    this.existingProduct = $(this.element).parent().data().editProduct
    this.productVariationInput = $(`#product_variation_id_${$(this.element).data().dishSetSelectionId}_${$(this.element).data().productSelectionId}`)
    this.mixableAttributesInput = $(`#mixableAttributes_${$(this.element).data().dishSetSelectionId}_${$(this.element).data().productSelectionId}`)
    this.groupSupplements = $(this.element).data().groupSupplement.sort((a, b) => {
      let a_order = $(this.element).data().localGroupSupplements[a.id] ? parseInt($(this.element).data().localGroupSupplements[a.id].order) : 1000
      let b_order = $(this.element).data().localGroupSupplements[b.id] ? parseInt($(this.element).data().localGroupSupplements[b.id].order) : 1000

      return a_order - b_order
    })
    if(this.existingProduct && this.existingProduct.productId != $(this.element).data().productId) {
      this.existingProduct = undefined
    }
    if(this.existingProduct) {
      this.productVariationInput.val(this.existingProduct.productVariationId)
    }
    this.addedGroupSupplements = []
    this.groupAttributeOrders = $(this.element).data().groupAttributeOrders
    this.productSelectionId = $(this.element).data().productSelectionId
    this.baseName = `basket_menu[dish_set_selections_attributes][${$(this.element).data().dishSetSelectionId}][product_selections_attributes][${this.productSelectionId}]`
    this.handleVariations()
    if($(this.element).data().mixables.length > 0) { this.handleMixables() }
    if($(this.element).data().seasoning.length > 0 ) { this.addSeasoning() }
    if($(this.element).data().removable.length > 0 ) { this.addRemovable() }
    this.addSupplement()
    if($(this.element).find('div').length == 0) {
      $(this.element).hide()
    }
    setTimeout(() => {
      $('#form_slider').get(0).controller.checkValid()
    }, 200)
    
  }

  addSeasoning() {
    let html = `
      <p class="basket-form-section__title"><span>Assaisonnement de ${$(this.element).data().name}</span></p>
      <fieldset class="form-group check_boxes optional seasonings_checkboxes form-group-valid ">
    `

    $.each($(this.element).data().seasoning, (i, e) => {
      let checked = $(this.element).data().attributes.seasonings.includes(e.id) 
      html += `
        <div class="custom-checkbox custom-control checkboxes_container">
          <input
            class="custom-control-input is-valid check_boxes optional"
            type="checkbox"
            ${checked ? 'checked' : ''}
            value="${e.id}"
            name="${this.baseName}[seasonings][]"
            id="basket_product_seasonings_${this.baseName}_${e.id}">
          <label
            class="collection_check_boxes mb-0 py-2 w-100"
            for="basket_product_seasonings_${this.baseName}_${e.id}">
            <span class="basket-form-customize__element-name">${e.name}</span>
          </label>
        </div>
      `
    })

    html += "</fieldset>"
    $(this.element).append(html)
  }

  addRemovable() {
    let html = `
      <p class="basket-form-section__title"><span>Personnalisation de ${$(this.element).data().name}</span></p>
      <fieldset class="form-group check_boxes optional seasonings_checkboxes form-group-valid ">
    `

    $.each($(this.element).data().removable, (i, e) => {
      let checked = $(this.element).data().attributes.removableIngredients.includes(e.id)
      html += `
        <div class="custom-checkbox custom-control checkboxes_container">
          <input
            class="custom-control-input is-valid check_boxes optional"
            type="checkbox"
            ${checked ? 'checked' : ''}
            value="${e.id}"
            name="${this.baseName}[removableIngredients][]"
            id="basket_product_removableIngredient_${this.baseName}_${e.id}">
          <label
            class="collection_check_boxes mb-0 py-2 w-100"
            for="basket_product_removableIngredient_${this.baseName}_${e.id}">
            <span class="basket-form-customize__element-name">${e.name}</span>
          </label>
        </div>
      `
    })

    html += "</fieldset>"
    $(this.element).append(html)
  }  

  addSupplement() {
    let groupSupplements = this.groupSupplements.filter((group) => {return !this.addedGroupSupplements.includes(group.id)})
    if(groupSupplements.length) {
      $(this.element).append(`
        <div class="basket-form-supplement-selections">
        </div>
      `)
      $.each(groupSupplements, (i, groupSupplement) => {
        $(this.element).find('> .basket-form-supplement-selections').append(
          new SupplementSelectionBuilder(groupSupplement, $(this.element).data(), false, this.baseName).build()
        )
      })
    }        
  }  

  getMixableAttribute(uuid) {
    return this.mixAttributeHandler.getMixableAttribute(uuid)
  }  

  handleMixables() {
    this.mixAttributeHandler = new MixAttributeHandler
    let result = this.mixAttributeHandler.populate(
      $(this.element).data().mixables,
      "article",
      $(this.element),
      $(this.element),
      this.groupSupplements,
      this.productSelectionId,
      this.baseName
    )
    this.addedGroupSupplements = result.groupSupplementIds
  }

  reloadMixables() {
    let attrs = []
    $.each($(this.element).find('.mixable_input'), (index, input) => {
      for (let i = 0; i < parseInt($(input).val()); i++) {
        attrs.push($(input).data().mixable)
      }
    })

    this.mixableAttributesInput.val(attrs.join('_'))   
  }   
}