import { Controller } from 'stimulus';
export default class SidedishselectionController extends Controller {
  static targets = ['input']

  connect() {
    this.set_total()
    this.element[this.identifier] = this
  }

  set_total() {
    var total = 0.0
    $.each(this.inputTargets, (index, input) => {
      total += this.input_price(input)
    })
    total = total - parseFloat($(this.inputTargets[0]).parents('.form_slide').eq(0).data().max)
    if(total < 0) {
      $('.total_sidedish').data('included', Math.abs(total))
      total = 0
    } else {
      $('.total_sidedish').data('included', $(this.inputTargets[0]).parents('.form_slide').eq(0).data().max)
    }
    $('.total_sidedish').html(total)
  }

  input_price(input) {
    return (parseFloat($(input).val()) * parseFloat($(input).data().price))
  }

  summary() {
    var html = ''
    $.each(this.inputTargets, (index, input) => {
      if ($(input).val() == 0) return true;

      html += `<li>Accompagnement : ${$(input).val()} ${$(input).data().name} - ${this.input_price(input)}€`
      let removable = $(`#sidedish_removable_${$(input).data().index} input:checked~label`).map(function() {return $(this).text()})
      if (removable.length) html += `, ${removable.get().join(', ')}`
      let seasoning = $(`#sidedish_seasoning_${$(input).data().index} input:checked`).map(function() {return $(this).text()})
      if (seasoning.length) html += `, ${seasoning.get().join(', ')}`
      html += '</li>'
    })
    return html
  }

  update_visibility(input) {
    $(`#sidedish_removable_${input.data().index}`).data('hide', input.val() == 0)
    $(`#sidedish_seasoning_${input.data().index}`).data('hide', input.val() == 0)
  }


  increase(e) {
    $(e.currentTarget).parent().find('input').val(parseFloat($(e.currentTarget).parent().find('input').val()) + 1)
    this.set_total()
    this.update_visibility($(e.currentTarget).parent().find('input'))
  }

  decrease(e) {
    let new_value = parseFloat($(e.currentTarget).parent().find('input').val()) - 1
    if(new_value >= 0) {
      $(e.currentTarget).parent().find('input').val(new_value)
      this.set_total()
      this.update_visibility($(e.currentTarget).parent().find('input'))
    }
  }

  change(e) {
    this.set_total()
  }
}
