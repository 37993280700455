import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
import { Client, Account } from 'appwrite';
import { v4 as uuidv4 } from 'uuid';
export default class InvitationnewversionController extends Controller {
  connect() {
    console.log('connected')
    this.email_valid = true
    this.full_name_valid = true
    this.email = $('#user_email').val()
    this.full_name = $('#user_full_name').val()
    this.password_valid = false
    this.password_confirm_valid = false
    this.password = ""
    this.password_confirm = ""
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
  }

  submit(e) {
    if($('#user_password').val().length == 0) {
      $('#user_password').next().text("Veuillez renseigner un mot de passe")
      $('#user_password').addClass('is-invalid')
    }
    if($('#user_password_confirmation').val().length == 0 && $('#user_password').val().length != 0) {
      $('#user_password_confirmation').next().text("Veuillez confirmer votre mot de passe")
      $('#user_password_confirmation').addClass('is-invalid')
    }
    e.preventDefault()
    if(this.email_valid && this.full_name_valid && this.password_valid && this.password_confirm_valid) {
      $('#registration_user .submit_button').prop('disabled', true)
      let form = $('#registration_user');
      this.register()
    }

  }

  redirect() {
    $('#registration_user').html(`
      <p>Votre compte a été créé.</p>
      <p>Vous allez recevoir un lien de vérifications dans quelques instants par mail. Merci de cliquer sur le lien pour vérifier votre compte.</p>
      <p>Vous allez être redirigé dans quelques instants.</p>
      <div class='position-relative' style='width: 100%; height: 60px;'>
        <span class="loading_container">
          <span class="loading_circle"></span>
        </span>
      </div>
    `)
    setTimeout(() => {
      Turbolinks.visit('/')
    }, 3000)
  }

  register() {
    this.account.create(
      uuidv4(),
      this.email,
      this.password,
      this.full_name
    ).then(async (response) => {
      $('#modal_menu').modal('hide')
      $('.loading_app_container').removeClass('d-none')
      await this.createSession()
      await this.signIn()
      await this.verifyEmail()

    }, function (error) {
      if(error.code == 409) {
        $('#registration_modal .user_email input').addClass('is-invalid')
        $('#registration_modal .user_email small').text("Un compte avec cette adresse email existe déjà.")
      }
      console.log(error); // Failure
      console.log(error.code);
    })
  }

  createSession() {
    return this.account.createEmailSession(this.email, this.password).catch((error) => { console.log(error) });
  }

  signIn() {
    return this.account.createJWT().then((response) => {
      localStorage.setItem('jwt', response.jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: response.jwt, init_user: true}
      }).done((data) => {
        this.redirect()
      })
    }, function (error) {
      console.log(error); // Failure
    });
  }

  verifyEmail() {
    return this.account.createVerification(`${process.env.callbackEndPoint}/verifyaccount/`).catch((error) => {
      console.log(error);
    });
  }

  check_full_name(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.full_name_valid = false
      input.next().text("Veuillez renseigner votre prénom et votre nom")
      return false;
    }
    input.addClass('is-valid')
    this.full_name_valid = true
    this.full_name = input.val()
  }

  full_name_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_full_name(input)
  }

  check_password(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Veuillez renseigner un mot de passe")
      return false;
    }
    if(input.val().length < 8) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Votre mot de passe n'est pas assez long (minimum 8 charactères)")
      return false;
    }
    input.addClass('is-valid')
    this.password_valid = true
  }

  password_change(e) {
    let input = $(e.currentTarget)
    this.password = $(e.currentTarget).val()
    input.removeClass('is-valid is-invalid')
    this.check_password(input)
  }

  check_password_confirm(input) {
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Veuillez confirmer votre mot de passe")
      return false;
    }
    if(input.val() != this.password) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Les deux mots de passe sont différents")
      return false;
    }
    input.addClass('is-valid')
    this.password_confirm_valid = true
  }

  password_confirm_change(e) {
    let input = $(e.currentTarget)
    this.password_confirm = $(e.currentTarget).val()
    input.removeClass('is-valid is-invalid')
    this.check_password_confirm(input)
  }
}
