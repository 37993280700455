import { Controller } from 'stimulus';
export default class ImportantmessageController extends Controller {
  connect() {
    if(!localStorage.getItem(`important_message_${$(this.element).data().key}`)) {
      $(this.element).addClass('active')
    }
  }

  hideMessage() {
    localStorage.setItem(`important_message_${$(this.element).data().key}`, 'hidden')
    $(this.element).removeClass('active')
  }
}