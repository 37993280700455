$(document).on('turbolinks:load', function() {
  if($('#shops_index').length && $('#search_index_address').length && $('#search_index_address').val().length > 0) {
    $.post({
      url: '/shops/search_location',
      data: {address: $('#search_index_address').val()}
    }).done(() => {
      if(!$('#shops_index').length) {
        window.location = '/'
      }
    })
    $('.index_location_city').text($('#search_index_address').val())
    $('.ariadne_location').text(`@ ${$('#search_index_address').val()}`)    
  } else {
    navigator.geolocation.getCurrentPosition(success_map, error_map);  
  }
  
  if($('#search_index_container').length) {
    var input = document.getElementById('search_index_address');
    var options = {
      types: ['geocode'],
      language: 'fr'
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setComponentRestrictions({
      country: ["fr", 'be'],
    });
    $(document).on('change', '#search_index_address', () => {
      setTimeout(() => {
        $.post({
          url: '/shops/search_location',
          data: {address: $('#search_index_address').val()}
        }).done(() => {
          if(!$('#shops_index').length) {
            window.location = '/'
          }
        })
        $('.index_location_city').text($('#search_index_address').val())
        $('.ariadne_location').text(`@ ${$('#search_index_address').val()}`)


      }, 500)
    })
  }
})

function success_map(pos) {
  let location = localStorage.getItem(`loc_${pos.coords.latitude}_${pos.coords.longitude}`)
  if(location) {
    display_location_result(JSON.parse(location))
  } else {
    let key = "AIzaSyBYK0Vo6ImWZQ-FworLGHF3ks0LSyho3vA";
    $.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&key=${key}`, (data) => {
      let result = {
        locality: data.results[0].address_components.filter(e => e.types.includes('locality'))[0].long_name,
        formatted_address: data.results[0].formatted_address
      }
      localStorage.setItem(`loc_${pos.coords.latitude}_${pos.coords.longitude}`, JSON.stringify(result))
      display_location_result(result)
    })
  }
  if($('#search_index_address').length && !$('#search_index_address').val().length > 0 && $('#shop_index_container').length) {
    $.post({
      url: '/shops/search_location',
      data: {coords: pos.coords}
    })
  }

}

function error_map(error) {
  $('.index_location_subtitle').addClass('invisible')
}

$(document).on('click', '#search_location_reset', () => {
  $('#search_index_address').val('')
  $('.ariadne_location').text('')
  navigator.geolocation.getCurrentPosition(success_map, error_map);
  if(!$('#shops_index').length) {
    window.location = '/'
  }
})

function display_location_result(result) {
  if($('.ariadne_location') && $('.ariadne_location').text().length) {
    $('.index_location_city').text($('.ariadne_location').text().replace('@ ', ''))
  } else {
    $('.index_location_city').text(result.locality)
  }

}
