import { Controller } from 'stimulus';
export class MainController extends Controller {

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  getControllerById(id) {
    return this.application.controllers.find(controller => {
      return controller.context.element.id === id;
    });
  }
}
