import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';

export default class FavoriteController extends MainController {
  toggle(e) {
    e.preventDefault()
    e.stopPropagation();
    if($(this.element).hasClass('favorite-container--not-signed')) {
      flashMessage('', 'Vous devez être connecté pour pouvoir ajouter une boutique à vos favoris !', 'warning')
      return false
    }
    let active = $(this.element).hasClass('favorite-container--active') ? 'false' : 'true'
    $(this.element).toggleClass('favorite-container--active')

    $.get(`/shops/toggle_favorite?shoppable=${$(this.element).data().shoppable}&active=${active}`)
    if($(this.element).hasClass('favorite-container--active')) {
      flashMessage('', `La boutique ${$(this.element).data().name} a bien été ajoutée à vos favoris !`, 'success')
    } else {
      flashMessage('', `La boutique ${$(this.element).data().name} a été enlevée de vos favoris !`, 'success')
      if($(e.currentTarget).parents('#modal-favs').length) {
        $(e.currentTarget).parents('.shop_card_container').remove()
      }
    }

    $(`.favorite-${$('#shop_show').data().shoppable}`).toggleClass('favorite-container--active', $(this.element).hasClass('favorite-container--active'))
  }
}