import { Client, Account } from 'appwrite';
import { Controller } from 'stimulus';

export default class OauthredirectController extends Controller {
  connect() {
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
    let shoppable = $(this.element).data()
    this.account.createJWT().then((response) => {
      localStorage.setItem('jwt', response.jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: response.jwt, init_user: true}
      }).done((data) => {
        let url
        if(shoppable.id) {
          url = `/shops/${shoppable.id}`
          if(shoppable.type.length) {
            url += `?shop_type=true`
          }
        } else {
          url = '/'
        }
        if($(window).width() < 900) {
          window.location = `${process.env.mobileUrl}/redirect_jwt?jwt=${response.jwt}`
        } else {
          Turbolinks.visit(url)
        }
        //
      })
    }, function (error) {
      console.log(error);
    });
  }
}
