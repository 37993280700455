import { Controller } from 'stimulus';
import { MainController } from './main_controller';
import PerfectScrollbar from 'perfect-scrollbar';
export default class SuccessController extends MainController {
  connect() {
    return
    const ps = new PerfectScrollbar('#checkout_modal .cart-product-content', {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
  }
}
