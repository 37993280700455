export class DishSetSelectionVariationHandler {
  addGroupAttribute(groupAttribute) {
    let attributs = groupAttribute.attributs.filter((a) => {
      return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id)})
    })
    let id = `basket_product_group_attribute_${this.productSelectionId}_${groupAttribute.id}`
    let name = `${this.baseName}[attributeCollectionId][${groupAttribute.group_type}[groupAttribute_${groupAttribute.id}]`
    $(this.element).append(`
      <fieldset class="radio_buttons optional basket_product_attribute w-100" id="${id}">
        <legend>Choix de ${groupAttribute.name}</legend>
        <input type="hidden" name="${name}" value="" autocomplete="off">
        ${this.addAttributes(attributs, groupAttribute)}
      </fieldset>
    `)
  }

  addAttributes(attributs, groupAttribute) {
    let html = ''
    $.each(attributs, (i, attribute) => {
      let checked
      if(this.existingProduct && (this.existingProduct.compositionAttributes.includes(attribute.id) || this.existingProduct.fabricationAttributes.includes(attribute.id))) {
        checked = true
      }
      let name = `${this.baseName}[attributeCollectionId][${groupAttribute.group_type}][groupAttribute_${groupAttribute.id}]`
      html += `
        <div class="form-check custom-checkbox custom-control">
          <input class="form-check-input radio_buttons optional custom-control-input" type="radio" value="${attribute.id}"
            name="${name}" id="basket_product_attribute_${this.productSelectionId}_${attribute.id}"
            data-action="dishsetselection#attributeSelected" ${checked ? 'checked' : ''}
          >
          <label class="form-check-label collection_radio_buttons" for="basket_product_attribute_${this.productSelectionId}_${attribute.id}">
            ${attribute.name}
          </label>
        </div>
      `
    })
    return html
  }

  attributeSelected(attribute) {
    let selectedAttributs = $(this.element).find(':checked').map((i, e) => { return $(e).val()}).get()
    this.selectedProductVariations = this.productVariations.filter((pv) => { 
      return selectedAttributs.every((a) => {
        return pv.attributeCollectionId.includes(a)
      })
    })
    let break_loop = false
    $.each($(this.element).find('.basket_product_attribute').not($(attribute).parents('.basket_product_attribute')), (i, attribut_container) => {
      if(break_loop) {
        //return false
      }
      $.each($(attribut_container).find('.form-check-input'), (i, input) => {
        if(break_loop) {
          //return false
        }
        let isIncluded = this.selectedProductVariations.some((pv) => { return pv.attributeCollectionId.includes($(input).val()) })
        if($(attribut_container).find(':checked').length == 0) {
          $(input).prop('disabled', !isIncluded)       
        } else {
          if(!isIncluded && $(input).prop('checked')) {
            $(input).prop('checked', false)
            $(input).prop('disabled', true)
            break_loop = true
            this.attributeSelected()
          } else if(isIncluded) {
            $(input).prop('disabled', false)
          }
        }

      })
    })
    this.selectVariation()
  }

  selectVariation() {
    if(this.selectedProductVariations && this.selectedProductVariations.length == 1) {
      this.productVariationInput.val(this.selectedProductVariations[0].id)
      $('#form_slider').get(0).controller.checkValid()
      return this.selectedProductVariations[0].price
    } else {
      this.productVariationInput.val("00000000-0000-0000-0000-000000000000")
      return null
    }
  }

  handleVariations() {
    if($(this.element).data().pvs.length > 0) {
      this.productVariations = $(this.element).data().pvs  
      this.selectedProductVariations = this.productVariations
      this.productSelectionId = $(this.element).data().productSelectionId
      this.baseName = `basket_menu[dish_set_selections_attributes][${$(this.element).data().dishSetSelectionId}][product_selections_attributes][${this.productSelectionId}]`
      this.groupAttributes = $(this.element).data().groupattribute.filter((e) => e.attributs.some((a) => {
        return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id) })
      })).sort((a, b) => {
        if(this.groupAttributeOrders && !isNaN(this.groupAttributeOrders[a.id]) && !isNaN(this.groupAttributeOrders[b.id])) {
          return this.groupAttributeOrders[a.id] > this.groupAttributeOrders[b.id] ? 1 : -1
        } else {
          return 1
        }
      })

      $.each(this.groupAttributes, (i, groupAttribute) => { this.addGroupAttribute(groupAttribute)})
    } else {
      this.productVariationInput.val("00000000-0000-0000-0000-000000000000")
    }    
  }
}