import { Controller } from 'stimulus';
import { initCheckoutModal } from '../shared/modal';
import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';
import { productHeader } from '../shared/product_header';
import { UpdatingLimitedProductQuantity } from '../shared/updating_limited_product_quantity'
import { jwtChecker } from '../shared/jwt_parser';
import PerfectScrollbar from 'perfect-scrollbar';
export default class CartcontentController extends MainController {

  connect() {
    jwtChecker().then((data) => {})
    this.element[this.identifier] = this
  }

  edit(e) {
    let data = $(e.currentTarget).parents('.modal-cart-container__content__items__item').data()
    let smartId = data.smartId
    let cartId = data.cartId
    let type = data.type
    $('.loading_app_container').removeClass('d-none')
    if($('#shop_show').length) {
      productHeader($(`#shop_${type}_${smartId}`).eq(0))
      $.get(`/basket_${type}s/${cartId}/edit`)
    } else {
      localStorage.setItem('edit_product', JSON.stringify({
        smartId: smartId,
        cartId: cartId,
        type: type
      }))
      let url = `/shops/${data.shop}`
      if(data.shoptype) {
        url += "?shop_type=pick_up"
      }

      Turbolinks.visit(url)
    }

  }

  codeKeyup(e) {
    if($('#discount_code').val().length > 1 && e.which == 13) {
      this.send_discount_code()
    }
  }

  remove(e) {
    $('.loading_app_container').removeClass('d-none')
    let data = $(e.currentTarget).parents('.modal-cart-container__content__items__item').data()
    $.ajax({
      url: `/cart/${data.cartId}?type=${data.type}`,
      type: 'DELETE',
      success: (data) => {
        if(data.code == 200) {
          this.getControllerByIdentifier('cart').refresh()
          UpdatingLimitedProductQuantity(data.updating_limited)
          this.refresh()
        }
      }
    })
  }

  toggleDiscountInput() {
    $('.modal-cart-container__content__miscs__discount-code__add-code').hide()
    $('.modal-cart-container__content__miscs__discount-code__input-container').show()
    $('.modal-cart-container__content__miscs__discount-code__input-container input').focus()
  }

  send_discount_code() {
    $.ajax({
      type: 'POST',
      url: '/cart/discount_code',
      data: {code: $('#discount_code').val()}
    }).done((data) => {
      if(data.result == 'not_found') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Ce code de réduction n'existe pas !")
      } else if (data.result == 'reload') {
        this.refresh()
      } else if (data.result == 'already_used') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Votre code a déjà été utilisé !")
      } else {
        $('.modal-cart-container__content__miscs__discount-code__error').text(data.result)
      }
      if(data.result != 'reload') {
        $('.modal-cart-container__content__miscs__discount-code__error').show()
      }
    })
  }

  remove_discount() {
    $.get('/cart/remove_discount', (data) => {
      this.refresh()
    })
  }

  async send_cart() {
    $('.loading_app_container').removeClass('d-none')
    jwtChecker().then((data) => {
      if(data == 'signed_in') {
        $.get('/check_current_user', (data) => {
          if(data.current_user) {
            if(!data.verified) {
              $('.loading_app_container').addClass('d-none')
              $('#modal_form .modal-content').html(`<div class='p-4' data-controller='cartverificationmodal'></div>`)
              //flashMessage('', "Votre compte n'est pas vérifié. Merci de cliquer sur le lien de vérification transmis par mail lors de la création de votre compte.", 'warning')
            } else {
              $.ajax({url: data.url, dataType: 'script'});
            }
          } else {
            $.get('/sessions/new?before_checkout=true')
          }
        })         
      } else {
        $.get('/sessions/new?before_checkout=true')
      }
     
    })
  }

  refresh() {
    $.get('/cart/reload')
  }
}
