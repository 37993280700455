require('./modal')
import { flashMessage } from '../shared/notify';
import { Client, Account, ID } from 'appwrite';
const appWriteClient = new Client();
appWriteClient
    .setEndpoint(process.env.apiEndpoint) // Your API Endpoint
    .setProject(process.env.projectId) // Your project ID
;

window.signInUser = function(jwt) {
  $.ajax({
    method: 'POST',
    url: '/appwrite/signin_user',
    data: {jwt: jwt}
  }).done((data) => {
    //flashMessage(data.name, '', 'success')
  })
}

window.checkSession = function() {
  let account = new Account(appWriteClient);
  account.getSession('current').then(function (response) {
    if(response.$id) {
      console.log(response)
    }
  }, function (error) {
    console.log(error); // Failure
  });
}

window.logoutUser = function() {
  $.ajax({
    method: 'DELETE',
    url: '/appwrite/signout_user'
  }).done((data) => {
    new Account(appWriteClient).deleteSession('current').then((response) => {
      console.log(response)
    }, (error) => {
      console.log(error)
      localStorage.removeItem('jwt');
    })
    //flashMessage("L'utilisateur a été déconnecté", '', 'success')
  })
}

window.showCart = function() {
  initModal(true, true)
  $('#modal_menu .custom_content').html(`<div class='cart_content'></div>`)
  $.get('/cart/reload')
  $('#modal_icon_cart_logo').show()
}

window.showShop = function(shopId) {
  $.get(`/shops/${shopId}/get_slug`, (data) => {
    if(data.slug) {
      Turbolinks.visit(`/shops/${data.slug}`)
    }
  })
}
