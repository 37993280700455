import { Controller } from 'stimulus';
export default class PasswordchangeController extends Controller {

  connect() {
    $(document).on('blur', '.emailedit input', (e) => {
      this.check_input($(e.currentTarget))
    })
    $('.emailedit_form').on('submit', (e) => {
      e.preventDefault()
      $.each(['email', 'password'], (index, field) =>{ this.check_input($(`#user_${field}`)) })


      if(!$('.is-invalid').length) {
        let form = $('.emailedit_form')
        $.ajax({
          type: 'POST',
          url: form.attr('action'),
          data: form.serialize(),
          success: function(data) {
            if(data.error) {
              $('.session_error').text(data.error)
              $('.session_error').removeClass('d-none')
            } else {
              $('#modal_menu').modal('hide')
              flashMessage('', 'Votre adresse email a bien été modifiée !', 'success')
            }
          }
        })
      }

      setTimeout(() => {
        $('.passwordchange_form .submit_button').prop('disabled', false)
      }, 100)
    })
  }

  check_input(input) {
    input.toggleClass('is-valid', input.val().length > 0)
    input.toggleClass('is-invalid', input.val().length == 0)
    return input.val().length > 0
  }
}
