export function stickyNav(ratio) {
  ratio = ratio * 2

/*  if(ratio > 1.5 && $('#shop_categories').length) {
    $('#shop_categories').css('borderRadius', '0 0 8px 8px')
  } else if ($('#shop_categories').length) {
    $('#shop_categories').css('borderRadius', '8px')
  }
  

  $('#nav_logo_container svg').css({
    transform: `translateX(-50%) scale(${(100 - 62 * ratio) / 100}`,
    top: -5 - 25 * ratio
  })*/
  ratio = ratio > 1 ? 1 : ratio
  $('nav').css({
    paddingTop: 16 - 8 * ratio,
    paddingBottom: 16 - 8 * ratio
  })
  $('#shoppable_logo_nav').css({
    width: 176 - 53 * ratio,
    height: 80 - 25 * ratio
  })
}
