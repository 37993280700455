import { flashMessage } from './notify';
import { Client, Account } from 'appwrite';

$(document).on('turbolinks:load', function() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  $('body').fadeIn(1000)
})

window.onresize = function(event) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

$(document).on('click', '.modal-default-myaccount__footer__signout', (e) => {
  e.preventDefault()
  const appWriteClient = new Client();
  appWriteClient
      .setEndpoint(process.env.apiEndpoint) // Your API Endpoint
      .setProject(process.env.projectId) // Your project ID
  ;
  localStorage.removeItem('jwt');
  $.ajax({
    url: '/appwrite/signout_user',
    type: 'DELETE',
    success: function(result) {
      new Account(appWriteClient).deleteSession('current').then((response) => {
        if($('#new_order').length) {
          location.reload();
          // Do something with the result
        } else {
          $.get('/reload_modal')
          flashMessage('', 'Vous avez été déconnecté !', 'success')
          $('.modal').modal('hide')
          $('#nav__cart').get(0).cart.refresh()
          location.reload();
          //Turbolinks.visit('/')
        }
      }, (error) => {
        console.log(error)
      })

    }
  });
})
