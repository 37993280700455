require('../libs/bootstrap-notify');

export function flashMessage (title, message, type) {
  $.notify({
    title: title,
    message: message,
    autoHide: false
  }, {
    type: type,
    autoHide: false,
    clickToHide: true,
    z_index: 100000,
    placement: {
      from: 'bottom',
      align: 'center'
    }
  })
}

$(document).on('turbolinks:load', function() {
  if($('.flash_message').length) {
    flashMessage('', $('.flash_message').text(), $('.flash_message').data().flash)
  }
  $(document).on('click', '.col-xs-11.alert', (e) => {
    $(e.currentTarget).remove()
  })
})
