import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
import { Client, Account, ID } from 'appwrite';
export default class SigninController extends Controller {

  connect() {
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
    this.element[this.identifier] = this
    $(document).on('keyup', '#new_session #user_password', (e) => {
      if(e.keyCode == 13) {
        this.check_password($('#new_session #user_password'))
        setTimeout(() => {
          $('#new_session .submit_button').click()
        }, 100)
        
      }
    })
  }

  submit(e) {
    this.check_email($(this.element).find('#new_session #user_email'))
    this.check_password($(this.element).find('#new_session #user_password:visible'))
    $(this.element).find('.submit_button').prop('disabled', true)
    e.preventDefault()
    if(this.email_valid && this.password_valid) {
      this.account.createEmailSession(this.email, this.password).then((response) => {
        this.account.createJWT().then((response) => {
          localStorage.setItem('jwt', response.jwt)
          $.ajax({
            method: 'POST',
            url: '/appwrite/signin_user',
            data: {jwt: response.jwt}
          }).done((data) => {
            $.get('/reload_modal')
            $('#modal_menu').modal('hide')
            $(this.element).find('.submit_button').prop('disabled', false)
            $('.favorite-container--not-signed').removeClass('favorite-container--not-signed')
            flashMessage('', 'Vous êtes désormais connecté !', 'success')
          })
        }, (error) => {
          $(this.element).find('.submit_button').prop('disabled', false)
          console.log(error); // Failure
        });
      }, (error) => {
        $(this.element).find('.submit_button').prop('disabled', false)
        $('#new_session .user_password input').removeClass('is-valid')
        $('#new_session .user_password input').addClass('is-invalid')
        $('#new_session .user_password input').next().text("Ce compte n'existe pas ou le mot de passe est incorrect.")
        console.log(error); // Failure
      });
    } else {
      $(this.element).find('.submit_button').prop('disabled', false)
    }
  }

  check_password(input) {
    input.removeClass('is-valid is-invalid')
    if(input.val().trim().length > 0) {
      this.password_valid = true
      this.password = input.val().trim()
      input.addClass('is-valid')
    } else {
      this.password_valid = false
      input.addClass('is-invalid')
      input.next().text("Veuillez renseigner votre mot de passe")
    }
  }

  password_change(e) {
    this.check_password($(e.currentTarget))
  }

  check_email(input) {
    input.removeClass('is-valid is-invalid')
    if(input.val().trim().length > 0) {
      this.email_valid = true
      this.email = input.val().trim()
      input.addClass('is-valid')
    } else {
      this.email_valid = false
      input.addClass('is-invalid')
      input.next().text("Veuillez renseigner votre adresse email")
    }
  }

  email_change(e) {
    this.check_email($(e.currentTarget))
  }
}
