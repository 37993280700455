import { Controller } from 'stimulus';
import { Client, Account } from 'appwrite';
export default class VerifyaccountController extends Controller {

  connect() {
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
    let params = new URLSearchParams(window.location.search)
    this.account.updateVerification(params.get('userId'), params.get('secret')).then((response) => {
      $('.verifyaccount_container').html(`
        <p>Votre compte a été vérifié.</p>
        <p>Vous allez être redirigé dans quelques instants.</p>
      `)
      setTimeout(() => {
        this.redirect()
      }, 1000)
    }, (error) => {
      this.redirect()
    })
  }

  redirect() {
    if($(window).width() < 900) {
      window.open("smartcollect://", "_self");
      setTimeout(() => {
        window.location = process.env.mobileUrl
      }, 2500);

    } else {
      Turbolinks.visit('/')
    }
  }
}
