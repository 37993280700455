import { Controller } from 'stimulus';
export default class SupplementselectionController extends Controller {
  static targets = ['input']

  connect() {
    this.set_total($(this.element))
    $(document).on('change', $(this.element).find('input'), () => {this.set_total($(this.element))})
  }

  set_total(element) {
    var total = 0.0
    $.each(element.find('input:checked'), function(index, input) {
      total += parseFloat($(input).data().price)
    })
    element.find('.total_seasoning').text(total)
  }

  change(e) {


  }
}
