import { Controller } from 'stimulus';
import { MainController } from './main_controller';
import PerfectScrollbar from 'perfect-scrollbar';
export default class OrdersController extends MainController {
  connect() {
    const ps = new PerfectScrollbar(`#${$(this.element).get(0).id}`, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
  }

  prevAccount() {
    $('#modal_menu').removeClass('show-custom-content') 
  }
}
