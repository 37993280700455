export function svgPlusCart() {
  return `
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M15.0005 6.25V23.75M6.25049 15H23.7505" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>        
  `
}

export function svgArrowRight() {
  return `
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.75 15H24.25M24.25 15L15.5 6.25M24.25 15L15.5 23.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  `
}

export function svgQuantityMinus() {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M3.33331 8H12.6666" stroke="#6B7281" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  `
}

export function svgQuantityPlus() {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.99998 3.33337V12.6667M3.33331 8.00004H12.6666" stroke="#6B7281" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  `
}