require('bootstrap/js/dist/modal');

window.initModal = function(custom_content, empty_custom_content) {
  $('.modal_border_svg').hide()
  hideSubs()
  if(!custom_content) {
    $('.custom_content').html('')
  }
  $('#modal_menu').toggleClass('show-custom-content', custom_content)
  $('#modal_menu').modal('show')
}

$('*').on('hide.bs.modal', (e) => {
  $('.modal_outside_logo').removeClass('d-flex')
  $('#modal_form').removeClass('.modal_delivery_date')
  $('.card_delivery_at_container').siblings().css('opacity', '1')
  $('#modal_icon_cart_logo').css('opacity', '1')
  $('#sub-modal-left').css('opacity', '1')
  if($('.modal-backdrop').length != 0) {
    setTimeout(() => {
      if($('.modal-backdrop').length != 0) {
        $('body').addClass('modal-open')
      }
    }, 500)
  }
})

function hideSubs() {
  $('#sub_modal_right').removeClass('shown')
  $('#sub_modal_right .modal_border_svg').hide()
  $('#sub_modal_right').css('width', '0')
  $('#sub_modal_end').removeClass('shown')
  $('#sub_modal_end .modal_border_svg').hide()
  $('#sub_modal_end').css('width', '0')
  $('#sub-modal-left').css('width', '470')
  setTimeout(() => { $('#sub-modal-left').addClass('shown')}, 500)
}

$(document).on('click', '[data-toggle=modal]', function(event) {
  initModal($(event.currentTarget).data().custom, true)
})

$(document).on('click', '#sub-modal-left', function(event) {
  if(!$('#sub-modal-left').hasClass('shown')) {
    hideSubs()
  }
})

$(document).on('click', '.order_block', (e) => {
  console.log($(e.currentTarget).data().legacy)
  if(!$(e.currentTarget).data().legacy) {
    $.get(`/orders/${$(e.currentTarget).get(0).id.split('_').reverse()[0]}`)
  }

})

$(document).on('click', '#sub_modal_right .close_menu', () => {
  if($('#sub_modal_end').hasClass('shown')) {
    $('.order_block').removeClass('hidden_order active')
    $('#sub_modal_end').removeClass('shown')
    $('#sub_modal_end .modal_border_svg').hide()
    $('#sub_modal_end').css('width', '0')
  } else {
    $('#sub_modal_right').removeClass('shown')
    $('#sub_modal_right .modal_border_svg').hide()
    $('#sub_modal_right').css('width', '0')
  }
  $('#sub-modal-left').css('width', '430')
  setTimeout(() => { $('#sub-modal-left').addClass('shown')}, 500)
})

$(document).on('click', '[data-loading]', function() {
  $('body').addClass('loading')
})

$(document).on('turbolinks:load', function() {
  $('body').removeClass('loading')
})
