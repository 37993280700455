import { Controller } from 'stimulus';
import { initModal } from '../shared/modal';
import { Client, Account } from 'appwrite';
export default class PasswordmailController extends Controller {

  connect() {
    $('#checkout_modal').modal('hide')
    $('#modal_menu').modal('show')
  }

  submit(e) {
    if(!this.email_valid) {
      $(this.element).find('.user_email input').addClass('is-invalid')
      $(this.element).find('.user_email small').text("Veuillez renseigner votre adresse email")
    } else {
      this.appWriteClient = new Client();
      this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
      this.account = new Account(this.appWriteClient);
      this.account.createRecovery($(this.element).find('.user_email input').val(), `${process.env.callbackEndPoint}/password/balancing`).catch(() => {});
      $(this.element).find('button').prop('disabled', true)
      $(this.element).replaceWith(`
        <div class='modal-custom-password-ask-change__answer'>
          <p>Si vous possédez un compte vous allez recevoir un mail dans quelques instants.</p>
          <p>Pour changer votre mot de passe il vous suffira de cliquer sur le lien contenu dans le mail.</p>
        </div>
      `)
    }
  }

  email_change(e) {
    this.check_email($(e.currentTarget))
  }

  check_email(input) {
    input.removeClass('is-valid is-invalid')
    if(input.val().length > 0) {
      this.email_valid = true
      input.addClass('is-valid')
    } else {
      this.email_valid = false
      input.addClass('is-invalid')
      input.next().text("Veuillez renseigner votre adresse email")
    }
  }
}
