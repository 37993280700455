import { Controller } from "stimulus"
import { MainController } from './main_controller';

export default class BasketproductfooterController extends MainController {
  connect() {
    this.element['controller'] = this
  }

  initForm() {
    setTimeout(() => {
      this.quantityInput = $('#basket_product_footer_quantity')
      this.quantityChanged()
    }, 200)

  }

  quantityIncreased() {
    this.quantityInput.val(parseFloat(this.quantityInput.val()) + 1)
    this.quantityChanged()
  }

  quantityDecreased() {
    if(parseFloat(this.quantityInput.val()) == 1) {
      return false 
    }
    this.quantityInput.val(parseFloat(this.quantityInput.val()) - 1)  
    this.quantityChanged()
  }

  quantityChanged() {
    if(isNaN(parseInt(this.quantityInput.val()))) {
      this.quantityInput.val(1)
    }
    $('#basket_product_quantity').val(this.quantityInput.val())
    $(this.element).find('.basket-form-quantity-buttons__decrease').toggleClass('hidden', parseFloat(this.quantityInput.val()) == 1)
    $('#form_slider').get(0).controller.checkValid(false)
  }

  submit() {
    $('#form_slider').get(0).controller.submit()
  }
}