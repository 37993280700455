import { MainController } from './main_controller';
import { Client, Account } from 'appwrite';
export default class CartverificationmodalController extends MainController {
  connect() {
    $(this.element).html(`
      <p>Votre compte n'est pas activé.</p>
      <p>Pour l'activez vous devez cliquer sur le lien de vérification qui vous a été envoyé lors de la création de votre compte.</p>
      <div class='text-right'>
        <p class='btn btn-outline-primary mr-2 mb-0' data-action="click->cartverificationmodal#closeVerificationModal">Fermer</p>
        <p class='btn btn-primary mb-0' style='width: auto;' data-action="click->cartverificationmodal#resendVerificationLink">Renvoyer un lien de vérification</p>
      </div>
    `)
    $('#modal_form').addClass('modal_delivery_date')
    $('#modal_form').modal('show')
    $('.modal-backdrop').eq(-1).removeClass("modal-backdrop");
    $('#sub-modal-left').css('opacity', '.5')
  }

  closeVerificationModal() {
    $('#modal_form').modal('hide')
  }

  resendVerificationLink() {
    let appWriteClient = new Client();
    appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    let account = new Account(appWriteClient);
    account.createVerification(`${process.env.callbackEndPoint}/verifyaccount/`).then(() => {
      $(this.element).html(`
        <p>Un nouveau lien de vérification vous a été envoyé.</p>
        <p>Une fois votre compte validé vous pourrez passer votre commande.</p>
        <div class='text-right'>
          <p class='btn btn-outline-primary mb-0' data-action="click->cartverificationmodal#closeVerificationModal">Fermer</p>
        </div>
      `)
    }, (error) => {
      console.log(error)
    })
  }
}
