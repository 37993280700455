export class StripeCoords {
  constructor() {
    $(document).on('blur', '.contact-information-form input', (e) => {
      if($(e.currentTarget).attr('name') == 'phone') {
        this.check_phone($(e.currentTarget))
      } else if ($(e.currentTarget).attr('name') == 'name') {
        this.check_name($(e.currentTarget))
      } else {
        this.check_input($(e.currentTarget))
      }
      
    })
    $(document).on('click', '.contact-information-container', () => { this.toggle_coords()  })
  }

  toggle_coords() {
    if($('.contact-information-form').hasClass('d-none')) {
      $('.contact-information-form').removeClass('d-none')
    } else {
      $.each(['address', 'postal_code', 'city', 'phone'], (index, field) =>{ this.check_input($(`#user_${field}`)) })
      if(!$('.contact-information-form .is-invalid').length) {
        $('.contact-information-form').addClass('d-none')
      }
    }
  }

  valid_coords() {
    $.each(['address', 'postal_code', 'city'], (index, field) =>{ this.check_input($(`#user_${field}`)) })
    this.check_phone($('#user_phone'))
    if($('#user_name').length) {
      this.check_name($('#user_name'))   
    }
    
    return $('.is-invalid').length == 0
  }

  check_input(input) {
    input.toggleClass('is-valid', input.val().length > 0)
    input.toggleClass('is-invalid', input.val().length == 0)
    return input.val().length > 0
  }

  check_name(input) {
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if(input.val().trim().length > 0 && !regex.exec(input.val())) {
      input.removeClass('is-invalid')
      input.addClass('is-valid')
      return true
    } else {
      input.addClass('is-invalid')
      input.removeClass('is-valid')
      return false
    }
  }

  check_phone(input) {
    input.toggleClass('is-valid', input.val().length > 0 && input.val().length < 17)
    input.toggleClass('is-invalid', input.val().length == 0 || input.val().length > 16)
    if(input.val().length == 0) {
      input.next().text('Veuillez renseigner votre numéro de téléphone')
    } else if(input.val().length > 16) {
      input.next().text('Votre numéro de téléphone doit contenir au maximum 16 caractères')
    }
  }
}
