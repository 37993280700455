import { Controller } from 'stimulus';
export default class SmartcollectproductpictureController extends Controller {
  connect() {
    console.log('SmartCollectProductPictureController')
    if($(this.element).find('.carousel-item').length > 1) {
      $(this.element).carousel({interval: false})
    } else {
      $(this.element).find('.carousel-control-prev').remove()
      $(this.element).find('.carousel-control-next').remove()
    }

  }

  change(e) {
    e.stopPropagation()
    $(this.element).carousel($(e.currentTarget).data().slide)
  }
}
